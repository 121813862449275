/** @format */
@import "~antd/dist/antd.less";
@import url(assets/font/icon/index.css);
@font-face {
  font-family: "GoogleSans-Regular";
  src: url("./assets/font/GoogleSans/GoogleSans-Regular.eot")
      format("embedded-opentype"),
    url("./assets/font/GoogleSans/GoogleSans-Regular.woff") format("woff"),
    url("./assets/font/GoogleSans/GoogleSans-Regular.ttf") format("truetype"),
    url("./assets/font/GoogleSans/GoogleSans-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans-Bold";
  src: url("./assets/font/GoogleSans-Bold/GoogleSans-Bold.eot")
      format("embedded-opentype"),
    url("./assets/font/GoogleSans-Bold/GoogleSans-Bold.woff") format("woff"),
    url("./assets/font/GoogleSans-Bold/GoogleSans-Bold.ttf") format("truetype"),
    url("./assets/font/GoogleSans-Bold/GoogleSans-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GoogleSans-Medium";
  src: url("./assets/font/GoogleSans-Medium/GoogleSans-Medium.eot")
      format("embedded-opentype"),
    url("./assets/font/GoogleSans-Medium/GoogleSans-Medium.woff") format("woff"),
    url("./assets/font/GoogleSans-Medium/GoogleSans-Medium.ttf")
      format("truetype"),
    url("./assets/font/GoogleSans-Medium/GoogleSans-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
* {
  font-family: "GoogleSans-Regular";
  margin: 0;
}

.login-flow-bg:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.logo .chalomart-logo {
  max-width: 175px;
}

body {
  font-family: "GoogleSans-Regular", sans-serif;
  font-size: 14px;
  color: #324954;
  line-height: 1.5;
  font-weight: 400;
  background-color: #fff;
  overflow-x: hidden;
  margin: 0;
}

.row {
  display: flex;
  /* -ms-flex-wrap: wrap; */
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-start {
  justify-content: flex-start;
}

.flex-row {
  flex-direction: row !important;
}

.d-block {
  display: block !important;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-primary {
  color: #ff7415 !important;
}

.col-secondary {
  color: #072331 !important;
}

.col-white {
  color: #fff !important;
}

.col-gray {
  color: #666666 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-primary {
  background-color: #ff7415 !important;
}

.bg-secondary {
  background-color: #072331 !important;
}

.bg-gray {
  background-color: #f4f4f4;
}

.bg-l-gray {
  background-color: rgba(248, 248, 248, 0.75);
}

.f-10 {
  font-size: 10px;
}

.f-12 {
  font-size: 12px;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-24 {
  font-size: 24px;
}

.f-normal {
  font-family: "GoogleSans-Regular" !important;
  font-weight: normal !important;
}

.f-medium {
  font-family: "GoogleSans-Medium";
}

/* .f-sbold {
      font-weight: 600;
  } */

.f-bold {
  font-family: "GoogleSans-Bold";
}

.pt-6 {
  padding-top: 6px !important;
}

.pl-24 {
  padding-left: 24px;
}

.mt-2 {
  margin-top: 2px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-1 {
  margin-left: 1px;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 1px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-3 {
  margin-right: 3px !important;
}

.mr-4 {
  margin-right: 4px;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-6 {
  margin-right: 6px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.h-2 {
  height: 2rem !important;
}

.forgotpassword {
  color: #6a7b83;
}
.text-center {
  text-align: center !important;
}

.login-box {
  padding: 30px 25px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  width: 400px;
  background-color: #fff;
  z-index: 100;
  /* position: relative;
  height: 85vh;
  overflow-y: auto; */
}
.cursor-pointer {
  cursor: pointer;
}
.full-width {
  width: 100%;
}
.login-flow-bg {
  background: url(./assets/img/login-flow-bg.jpg) no-repeat center center;
  position: absolute;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.signup-input-container {
  border-radius: 3px;
}
@import "~antd/dist/antd.less";

.logo {
  justify-content: center;
}

.blur {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  height: 100vh;
  width: 50%;
}
.model-msm {
  border: #ff7415;
  border-radius: 50px;
}

.otp-box {
  width: 56px !important;
  padding: 3px !important;
  height: 1.7rem !important;
  color: #495057;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;
  height: 35px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #cdd3d6;
  margin-left: 24px;
}
.otp-box1 {
  width: 46px !important;
  padding: 3px !important;
  height: 1.7rem !important;
  color: #495057;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;
  height: 35px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 1px solid #cdd3d6;
  margin-left: 10px;
}

.otp-border {
  border: 2px solid #ff7415;
  border-radius: 10px;
}

.otp-text {
  font-size: 16px;
  color: #000000;
  font-family: "GoogleSans-Medium";
}

.ant-input-lg {
  font-size: 14px !important;
}

.ant-input-affix-wrapper-lg {
  font-size: 14px !important;
}

.ant-row.hybrid {
  margin-bottom: -25px !important;
}

@primary-color: #ff7415;